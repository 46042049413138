import React from "react";
import { Grid, PageContent, Box, Heading, Card } from "grommet";
import CardTemplate from "./CardTemplate";
import Chatbot from "./Chatbot";

const History = ({lang, dark}) => {
  const historyData = [
    {
        date: "· 1858 ·",
        content:
          "Jan Laichter se narodil 28. prosince 1858 v Dobrušce. Jeho otec byl pekařem a později i starostou města. Jan se vyučil kupcem a po několika letech strádání na zkušené se mu podařilo získat vysněné místo v nakladatelství Jana Otty v Praze.",
      },
      {
        backgroundImage: "/img/png/dobruska.png",
      },
      {
        date: "· 1893 ·",
        content:
          "V nakladatelství Jana Otty se Jan Laichter seznámil s profesorem T. G. Masarykem, s nímž a spolu s dalšími významnými autory založil v roce 1893 vlastní revue Naše doba. Podtitul zněl \"Revue pro vědu, umění a život sociální\". Bylo to právě spojení společensko-vědního obsahu s kulturním přehledem, které odlišilo Naší dobu od ostatních časopisů té doby.",
      },
      {
        backgroundImage: "/img/png/tgm.png",
      },
      {
        date: "· 1896 ·",
        content:
          "Spolu s tímto časopisem získal Jan Laichter možnost vybudovat své vlastní nakladatelství. V roce 1896 začal vydávat svůj „Výbor nejlepších spisů poučných” a posléze i další edice. Díla vycházela po sešitech, díky čemuž se mohla šířit i do méně zámožných vrstev společnosti.",
      },
      {
        backgroundImage: "/img/png/books.png",
      },
      {
        date: "· 1908 ·",
        content:
          "Byl to architekt Jan Kotěra, na kterého se v roce 1908 Jan Laichter obrátil s prosbou, aby mu navrhl jeho vlastní nakladatelský dům. V zadání ho inspiroval Plantinův dům v Antverpách, kde nakladatelova rodina po století na jednom míste zároveň žila a pracovala. Kotěra toto zadání realizoval formou strohé moderny, která v mnoha směrech předběhla o desetiletí tehdejší vývoj v architektuře.",
      },
      {
        backgroundImage: "/img/png/sketch.png",
      },
      {
        date: "· 1911 ·",
        content:
          "V roce 1911 začala práce na jednom z Laichterových nejambicióznějších projetů, kterým byla edice České dějiny. Tato edice měla za cíl nabídnout souborné zpracování české historie a nahradit tak a revidovat Dějiny Palackého. Z důvodu následného politického vývoje však nebyl projekt nikdy dokončen.",
      },
      {
        backgroundImage: "/img/png/pisarna.png",
      },
      {
        date: "· 1945 ·",
        content:
          "Jako dosud jedinému nakladateli udělila Univerzita Karlova v roce 1945 Janu Laichterovi čestný doktorát (Dr.h.c.) za jeho životní zásluhy o vývoj české knižní kultury.",
      },
      {
        backgroundImage: "/img/png/dhc.png",
      },
      {
        date: "· 1946 ·",
        content:
          "Po smrti Jana Laichtera 31. října 1946 přebírá nakladatelství v plném rozsahu jeho syn František Laichter.",
      },
      {
        backgroundImage: "/img/png/fl.png",
      },
      {
        date: "· 1949 ·",
        content:
          "V rámci komunistického zákona, který zakazoval působení soukromých nakladatelství, zaniklo v roce 1949 celkem 335 knižních vydavatelství, z nichž jedním bylo také právě nakladatelství Jana Laichtera. František Laichter mohl jen bezmocně sledovat, jak byly knihy ze skladu po tisících v nákladních autech odvezeny do stoupy. V roce 1961 byla pak i jeho rodina z domu vystěhována.",
      },
      {
        backgroundImage: "/img/png/ksc.png",
      },
  ];

  const historyDataEn = [
    {
      date: "· 1858 ·",
      content:
        "Jan Laichter was born on December 28, 1858, in Dobruška. His father was a baker and later mayor of the city. Jan apprenticed as a merchant, and after several years of struggling, he managed to obtain his dream job at the publishing house of Jan Otto in Prague.",
    },
    {
      backgroundImage: "/img/png/dobruska.png",
    },
    {
      date: "· 1893 ·",
      content:
        "At Jan Otto's publishing house, Jan Laichter met Professor T. G. Masaryk, with whom he founded his own magazine, Naše doba, in 1893, along with other prominent authors. The subtitle was \"A Review for Science, Art, and Social Life.\" It was the combination of social science content with a cultural overview that distinguished Naše doba from other magazines of the time.",
    },
    {
      backgroundImage: "/img/png/tgm.png",
    },
    {
      date: "· 1896 ·",
      content:
        "Together with the magazine, Jan Laichter had the opportunity to establish his own publishing house. In 1896, he began publishing his \"Selection of the Best Educational Writings\" and later other editions. Works were published in installments, allowing them to spread to less affluent segments of society.",
    },
    {
      backgroundImage: "/img/png/books.png",
    },
    {
      date: "· 1908 ·",
      content:
        "In 1908, Jan Laichter approached architect Jan Kotěra with a request to design his own publishing house. He was inspired by the Plantin House in Antwerp, where the publisher's family had lived and worked for centuries. Kotěra fulfilled the task in the form of austere modernism, which in many ways anticipated the architectural development of the following decade.",
    },
    {
      backgroundImage: "/img/png/sketch.png",
    },
    {
      date: "· 1911 ·",
      content:
        "In 1911, work began on one of Laichter's most ambitious projects, the Czech History edition. This edition aimed to provide a comprehensive treatment of Czech history, replacing and revising Palacký's History. Due to subsequent political developments, however, the project was never completed.",
    },
    {
      backgroundImage: "/img/png/pisarna.png",
    },
    {
      date: "· 1945 ·",
      content:
        "In 1945, Charles University awarded Jan Laichter an honorary doctorate (Dr.h.c.) for his lifetime contributions to the development of Czech book culture, the only publisher to have received such an honor.",
    },
    {
      backgroundImage: "/img/png/dhc.png",
    },
    {
      date: "· 1946 ·",
      content:
        "After Jan Laichter's death on October 31, 1946, his son František Laichter took over the publishing house in its entirety.",
    },
    {
      backgroundImage: "/img/png/fl.png",
    },
    {
      date: "· 1949 ·",
      content:
        "As part of the communist law, which prohibited the operation of private publishing houses, a total of 335 book publishers disappeared in 1949, one of which was also the publishing house of Jan Laichter. František Laichter could only helplessly watch as thousands of books were taken from the warehouse in trucks to be burned. In 1961, his family was also evicted from the house.",
    },
    {
      backgroundImage: "/img/png/ksc.png",
    },
  ]

  const PageHeader = ({ title }) => (
    <Box>
      <Heading level="2" margin={{ top: "medium", bottom: "medium" }} weight="bold">
        {title}
      </Heading>
    </Box>
  );

  return (
    <PageContent>
      <Box margin={{ 
        top: "100px",
        bottom: "100px", 
      }}>
        <PageHeader />
        <Grid columns="medium" gap="large" pad={{ bottom: "large" }}>
          {/* Use the ternary operator to select the appropriate language content */}
          {(lang === "en" ? historyDataEn : historyData).map(({ date, content, backgroundImage }) => (
            <CardTemplate 
              key={date} 
              title={date} 
              text={content} 
              myBackgroundImage={backgroundImage} 
            />
          ))}
        </Grid>
      </Box>
    </PageContent>
  );
};

export default History;