import { deepMerge } from "grommet/utils";
import { grommet } from 'grommet/themes';

export const customTheme = deepMerge(grommet, {
  global: {
    colors: {
      brand: '#f3eede',
      background: {
        light: '#f3eede',
        dark: '#000'
      },
      text: {
        light: '#000',
        dark: '#fff'
      }, 
      focus: {
        light: '#brand',
        dark: '#000'
      },
    },
    font: {
      size: '14px',
      family: "'Crabath', Times New Roman, serif",
      italic: true,
    }, 
  },
  heading: {
    font: {
      family: "'Jan Bold', Arial, sans-serif",
    },
    level: {
      1: {
        font: {
          weight: 700,
        },
      },
      2: {
        font: {
          weight: 700,
        },
      },
    },
  },
  button: {
    font: { // Add the font property
      family: "'Jan Bold', Arial, sans-serif", // Set the font family here
      italic: true,
    },
    default: {
      color: 'text',
      border: undefined,
      padding: {
        horizontal: '20px',
        vertical: '20px',
      },
    },
    primary: {
      background: { color: 'transparent' },
      border: { 
        width: '0.5px',
        radius: '50px',
        color: 'text',
       },
      padding: {
        horizontal: '30px',
        vertical: '15px',
      },
    },
    hover: {
      background: { color: 'transparent' },
      primary: {
        color: {
          light: '#000',
          dark: '#000'
        },
        border: {
          color: 'text',
          radius: '24px',
        },
      },
    },
  },  
  anchor: {
    color: "text",
    fontWeight: 400,
    textDecoration: "none",
    hover: {
      textDecoration: "underline",
    },
  },
  tag: {
    background: 'none',
    border: {
      color: 'text',
    },
    hover: {
      background: 'none',
      border: {
        color: 'red',
        width: '3px',
      },
    },
  },
});

export default customTheme;