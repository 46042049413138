import React from 'react';
import { Box, Button, TextInput, Text } from 'grommet';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url = 'https://house.us12.list-manage.com/subscribe/post?u=02a047e5fbb9312cde4533a3a&amp;id=17babd874d&amp;f_id=00ef41e0f0';

const translations = {
  en: {
    subscribe: '· SUBSCRIBE ·',
    sending: 'sending...',
    succes: 'Thank you for subscribing',
    error: 'Something went wrong, please try again later.',
    placeholder: 'your@email.com',
  },
  cs: {
    subscribe: '· POTVRDIT ·',
    sending: 'odesílání...',
    succes: 'Děkujeme za zájem',
    error: 'Něco se pokazilo, zkuste to prosím později.',
    placeholder: 'vas@email.cz',
  },
};

const CustomForm = ({ status, message, onValidated, lang, dark }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <Box 
      background="light-1" 
      pad="small" 
      round="xsmall" 
      style={{ 
        display: 'inline-block', 
        backgroundColor: 'transparent'
      }}
    >
      {status === 'sending' && <Text color="blue">{translations[lang].sending}</Text>}
      {status === 'error' && <Text color="red">{translations[lang].error}</Text>}
      {status === 'success' && <Text color="green">{translations[lang].succes}!</Text>}
      <TextInput
        size="large"
        ref={node => (email = node)}
        type="email"
        placeholder={translations[lang].placeholder}
        style={{
          border: dark ? '#fff 0.5px solid' : '#000 0.5px solid',
          borderRadius: '30px',
          paddingLeft: '30px',
          fontSize: '16px',
          color: dark ? '#fff' : '#000',
          fontWeight: 'normal',
        }}
      />
      <Box pad={{ top: 'small' }} align="center" justify="center">
        <Box width="fit-content">
          <Button
            primary
            onClick={submit}
            label={translations[lang].subscribe}
            type="button"
            style={{
              padding: '15px 30px 15px 30px',
              color: dark ? '#fff' : '#000',
              border: dark ? '#fff 0.5px solid' : '#000 0.5px solid',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const MailchimpSignupForm = ({ dark, lang = 'en' }) => {
  return (
    <Box>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            lang={lang}
            dark={dark}
            style={{ backgroundColor: 'transparent' }}
          />
        )}
      />
    </Box>
  );
};

export default MailchimpSignupForm;