import { Stack, Box, Heading } from 'grommet';
import { ReactComponent as SvgBackground } from '../svg/background-01.svg';

function HomepageCover({ dark, lang }) {
  return (
    <Stack 
      anchor="center" 
      style={{ 
        overflow: 'hidden',
      }} 
      pad={{ right: 'none', left: 'none' }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SvgBackground 
          className={`st0 ${dark ? 'dark-mode' : ''}`} 
          height="90vh"
          width="1920px"
          preserveAspectRatio="xMidYMid meet"
          style={{ 
            display: 'flex',
          }}
        />
      </Box>
      <Box style={{ alignItems: 'center', width: '90vw' }}>
        <Heading
          className="responsive-heading"
          align="center"
          level={1}
          margin="none"
        >
          <b> {lang === 'en' ? '·\u00A0LAICHTER\u00A0·\u00A0HOUSE\u00A0·' : '·\u00A0LAICHTERŮV\u00A0·\u00A0DŮM\u00A0·'}</b>
        </Heading>
      </Box>
    </Stack>
  );
}

export default HomepageCover;