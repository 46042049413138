import React from "react";
import { Grid, PageContent, Box, Button, Heading, Anchor, Card, Text } from "grommet";
import CardTemplate from "./CardTemplate";
import TestimonialsLogos from "./TestimonialsLogos";
import Chatbot from "./Chatbot";

const Spaces = ({dark, lang}) => {
  const spacesTextEn = {
    rentalTitle: "· RENTAL ·",
    rentalText:
      "In the Laichter House, there are residential and non-residential spaces occasionally available for rent. We have experience with complete event production and are happy to adapt everything to your needs. In the past, we have hosted lectures and discussions, social events, workshops, performances and concerts, weddings, christenings, markets, film shoots and photo sessions.",
    contactUs: "· CONTACT US ·",
    collaboratedWithTitle: "· WORKED WITH US ·",
    addressTitle: "· ADDRESS ·",
    mapLabel: "· MAP ·",
    contactTitle: "· CONTACT ·",
    contactText:
      "If you are interested in renting a space in Laichter House, you can contact Kristýna directly.",
  };

  const PageHeader = ({ title }) => (
    <Box>
      <Heading level="2" margin={{ top: 'medium', bottom: 'medium' }} weight="bold">
        {title}
      </Heading>
    </Box>
  );

  return (
    <PageContent>
      <Box margin={{ 
        top: "100px",
        bottom: "100px" 
      }}>
            <PageHeader/>
            <Grid columns="medium" gap="large" pad={{ bottom: "large" }}>
                <CardTemplate
                  myBackgroundImage={'/img/gif/events.gif'}
                />  
                <CardTemplate
                  title={lang === 'en' ? spacesTextEn.rentalTitle : "· PRONÁJEM ·"}
                  text={lang === 'en' ? spacesTextEn.rentalText : "V Laichterově domě se nacházejí bytové i nebytové prostory, které jsou občas k pronájmu. Máme zkušenosti s kompletní produkcí událostí a rádi vše přizpůsobíme vašim potřebám. V minulosti jsme například hostovali přednášky a diskuze, společenské akce, workshopy, představení a koncerty, svatby, křty, markety, filmová natáčení a focení."}
                  element={
                    <Box margin={{ top: "medium" }} style={{ alignItems: "center" }}>
                      <Button
                      style={{ fontFamily: "Jan Bold" }}
                      type="button"
                      target="_blank"
                      href="mailto: dobry.den@laichter.house"
                      label={lang === 'en' ? spacesTextEn.contactUs : "· NAPIŠTE NÁM ·"}
                      color={"transparent"}
                      primary
                      />
                    </Box>
                    }
                  />
                  <CardTemplate
                    title={lang === 'en' ? spacesTextEn.collaboratedWithTitle : "· SPOLUPRACOVALI S\u00A0NÁMI ·"}
                    mainElement={<TestimonialsLogos dark={dark} />}
                  />
                  <CardTemplate
                    title={lang === 'en' ? spacesTextEn.addressTitle : "· ADRESA ·"}
                    text={
                      <Box>
                        <Anchor href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8" target="_blank">Chopinova 1543/4</Anchor>
                        <Anchor href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8" target="_blank">Praha 2–Vinohrady, 120 00</Anchor>
                        <Anchor href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8" target="_blank">Česká republika, EU </Anchor>
                      </Box>
                    }
                    element={
                      <Box margin={{ top: "medium" }} style={{ alignItems: "center" }}>
                        <Button
                        style={{ fontFamily: "Jan Bold" }}
                        type="button"
                        target="_blank"
                        href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8"
                        label={lang === 'en' ? spacesTextEn.mapLabel : "· MAPA ·"}
                        color={"transparent"}
                        primary
                        />
                      </Box>
                    }
                  />
                  <CardTemplate
                  title={lang === 'en' ? spacesTextEn.contactTitle : "· KONTAKT ·"}
                  text={
                    <Box>
                      <Text>{lang === 'en' ? spacesTextEn.contactText : "V případě zájmu o pronájem prostor v Laichterově domě se můžete obrátit přímo na Kristýnu."}</Text>
                      <br/>
                      <Anchor href="mailto: kristyna@laichter.house" label="Email: kristyna@laichter.house" />
                      <Anchor href="tel:+420724931925" label="Tel.: +420 724 931 925" />
                    </Box>
                  }
                  element={
                    <Box margin={{ top: "medium" }} style={{ alignItems: "center" }}>
                      <Button
                      type="button"
                      style={{ fontFamily: "Jan Bold" }}
                      href="mailto: kristyna@laichter.house"
                      label={lang === 'en' ? spacesTextEn.contactUs : "· NAPIŠTE NÁM ·"}
                      color={"transparent"}
                      primary
                      />
                    </Box>
                  }
                  />
                  <Card elevation="none" border={true} round="xsmall" pad="xsmall">
                    <Chatbot lang={lang} dark={dark} />
                  </Card>
        </Grid>
      </Box>
    </PageContent>
  );
};

export default Spaces;
