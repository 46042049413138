import { useState, useEffect } from "react";
import { Box, Text, Anchor, Carousel } from "grommet";

const testimonials = [
  {
    text: 'Jan Žaloudek a Jolanta Trojak: "Laichterův dům je pro nás nejkrásnějším místem, v jakém můžeme v Praze žít."',
    author: "Czechdesign", 
    url: "https://www.czechdesign.cz/temata-a-rubriky/bydlet-jako-designer-laichteruv-dum-je-pro-nas-nejkrasnejsim-mistem-v-jakem-muzeme-v-praze-zit-rikaji-partneri-jan-zaloudek-a-jolanta-trojak",
  },
  {
    text: '"Vinohradská perla moderny. Laichterův dům patří mezi vrcholná díla slavného architekta Jana Kotěry."',
    author: "Lidové noviny",
    url: "https://www.lidovky.cz/relax/design/vinohradska-perla-moderny-laichteruv-dum-patri-mezi-vrcholna-dila-slavneho-architekta-jana-kotery.A210816_150001_ln-bydleni_ape",
  },
  {
    text: '"Skrytá perla Vinohrad pootevírá své brány. V Laichterově domě se psaly dějiny."',
    author: "Forbes",
    url: "https://forbes.cz/skryta-perla-vinohrad-pootevira-sve-brany-v-laichterove-dome-se-psaly-dejiny/",
  },
  {
    text: '"Pokud tušíte o existenci Laichterova domu na pražských Vinohradech, nebo se vám dokonce podařilo spatřit jeho interiér na vlastní oči, patříte mezi hrstku šťastlivců."',
    author: "ELLE",
    url: "https://www.elle.cz/elle-decoration/utajeny-klenot",
  },
  {
    text: '"Laichterův dům od architekta Kotěry předhonil dobu."',
    author: "Český Rozhlas",
    url: "https://praha.rozhlas.cz/laichteruv-dum-od-architekta-kotery-predhonil-dobu-7298215",
  },
  {
    text: '"Budova je – spolu s vlastním domem v Hradešínské ulici a budovou muzea v Hradci Králové - mistrovským dílem Jana Kotěry z jeho vrcholného tvůrčího období."',
    author: "TV Architect",
    url: "https://www.tvarchitect.com/video/skryte-poklady-architektury-79-dil-laichteruv-dum/",
  },
];

const testimonialsEn = [
  {
  text: 'Jan Žaloudek and Jolanta Trojak: "For us, Laichter House is the most beautiful place we can live in Prague."',
  author: "Czechdesign",
  url: "https://www.czechdesign.cz/temata-a-rubriky/bydlet-jako-designer-laichteruv-dum-je-pro-nas-nejkrasnejsim-mistem-v-jakem-muzeme-v-praze-zit-rikaji-partneri-jan-zaloudek-a-jolanta-trojak",
  },
  {
  text: "Vinohrady's modernist gem. Laichter House is among the masterpieces of the famous architect Jan Kotěra.",
  author: "Lidové noviny",
  url: "https://www.lidovky.cz/relax/design/vinohradska-perla-moderny-laichteruv-dum-patri-mezi-vrcholna-dila-slavneho-architekta-jana-kotery.A210816_150001_ln-bydleni_ape",
  },
  {
  text: '"Hidden gem of Vinohrady opens its doors. History was written in Laichter House."',
  author: "Forbes",
  url: "https://forbes.cz/skryta-perla-vinohrad-pootevira-sve-brany-v-laichterove-dome-se-psaly-dejiny/",
  },
  {
  text: "If you know about the existence of Laichter House in Prague's Vinohrady, or have even managed to see its interior with your own eyes, you belong to a handful of lucky ones.",
  author: "ELLE",
  url: "https://www.elle.cz/elle-decoration/utajeny-klenot",
  },
  {
  text: '"Laichter House by architect Kotěra was ahead of its time."',
  author: "Český Rozhlas",
  url: "https://praha.rozhlas.cz/laichteruv-dum-od-architekta-kotery-predhonil-dobu-7298215",
  },
  {
  text: "The building is - along with the architect's own house on Hradešínská Street and the museum building in Hradec Králové - a masterpiece of Jan Kotěra's peak creative period.",
  author: "TV Architect",
  url: "https://www.tvarchitect.com/video/skryte-poklady-architektury-79-dil-laichteruv-dum/",
  },
];

const Testimonials = ({lang}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
    setIndex((index + 1) % testimonials.length);
    }, 7000);
    return () => clearInterval(interval);
    }, [index]
  );
    
  return (
    <Box align="center" margin={{ top: "medium" }} height="100%">
      <Carousel fill play={8000} initialChild={index} controls={"arrows"}>
        {(lang === "en" ? testimonialsEn : testimonials).map((testimonial) => (
          <Box key={testimonial.url} height="100%">
            <Box pad={{ horizontal: "large" }}>
              <Text>
                <i>{testimonial.text}</i>
              </Text>
            </Box>
            <Box align="end" margin={{ top: "medium" }}>
              <Anchor target="_blank" href={testimonial.url}>
                <Box pad={{ horizontal: "large" }}>
                  <Text>- {testimonial.author}</Text>
                </Box>
              </Anchor>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
    
    export default Testimonials;