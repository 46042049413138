import { Box } from 'grommet';
import { WingbotChat } from 'wingbot-chat-ui';
import '../Chat.css';

const Chatbot = ({ lang, dark }) => {
  // change cz to cs 
  let local_lang = ''
  if (lang === 'cs') {
    local_lang = 'cs';
  } else {
    local_lang = lang;
  }
  return (
    <Box fill style={{ position: 'relative', height: '60vh' }}>
      {local_lang === 'cs' ? (
        <WingbotChat
          key="cz"
          pageId="wju2OtQHUC6K.8TH49hLO9ykcObIprod"
          contextUserAgentProp="ua"
          contextScreenResolutionProp="sr"
          uploadEnabled={false}
          fullscreen={false}
          autoStart={true}
          contextLangProp="lang"
          context={{ userId: local_lang }}
          lang={local_lang}
        />
      ) : (
        <WingbotChat
          key="en"
          pageId="wju2OtQHUC6K.8TH49hLO9ykcObIprod"
          contextUserAgentProp="ua"
          contextScreenResolutionProp="sr"
          uploadEnabled={false}
          fullscreen={false}
          autoStart={true}
          contextLangProp="lang"
          context={{ userId: local_lang }}
          lang={local_lang}
        />
      )}
    </Box>
  );
};

export default Chatbot;
