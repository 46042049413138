import { useState, useEffect } from "react";
import { Box, Image, Carousel } from "grommet";

const testimonials = [
  {
    backgroundImage: "/img/png/logos/hermes",
  },
  {
    backgroundImage: "/img/png/logos/ac",
  },
  {
    backgroundImage: "/img/png/logos/stillking",
  },
  {
    backgroundImage: "/img/png/logos/host",
  },
  {
    backgroundImage: "/img/png/logos/sf",
  },
];

const Testimonials = ({ dark }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % testimonials.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [index]);

  console.log('Dark prop value:', dark); // Log the dark prop value

  return (
    <Box align="center" justify="center" margin={{ top: "medium" }}>
      <Carousel fill play={2000} initialChild={index} controls={"arrows"}>
        {testimonials.map((testimonial) => {
          const imageUrl = `${testimonial.backgroundImage}${dark ? "_w.png" : ".png"}`;
          return (
            <Box height={{max: "auto"}} width={{max: "100%"}} key={testimonial.backgroundImage}>
              <Image
                fit="contain"
                src={imageUrl}
              />
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default Testimonials;
