import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Paragraph,
} from "grommet";

const CardTemplate = ({
  title,
  text,
  mainElement,
  element,
  footer,
  myBackgroundImage,
}) => {

  return (
    <Card
      className="custom-card"
      elevation="none"
      border={myBackgroundImage ? false : true}
      round="xsmall"
      pad="medium"
      style={{
        backgroundImage: `url(${myBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "400px",
        overflow: "hidden",
      }}
    >
      <CardHeader pad="medium">
        <Heading level={3} margin="none">
          <b>{title}</b>
        </Heading>
      </CardHeader>
      {mainElement}
      <CardBody pad="medium">
        <Paragraph >{text}</Paragraph>
      </CardBody>
      {element}
      {footer && (
        <CardFooter pad="medium" background="background-contrast">
          {footer}
        </CardFooter>
      )}
    </Card>
  );
};

export default CardTemplate;