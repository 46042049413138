import { useState, useEffect } from 'react';
import {
  Header,
  Box,
  DropButton,
  Nav,
  Anchor,
  Button,
  ResponsiveContext,
  Text,
} from 'grommet';
import { Moon, Sun } from 'grommet-icons';
import '../App.css';
import { ReactComponent as SvgLogo } from '../svg/lh_logo.svg';
import { Link, useLocation } from 'react-router-dom';

const AppBar = (props) => (
    <Header
      pad={{ left: "medium", right: "xsmall", vertical: "xsmall" }}
      elevation="none"
      margin={"small"}
      border={true}
      round="large"
      style={{
        position: "fixed",
        top: "0",
        width: "95%",
        alignSelf: "center",
        zIndex: "1000",
      }}
      {...props}
    />
  );

  const MenuBar = ({ dark, setDark, lang, setLanguage }) => {

    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(location.pathname);

    useEffect(() => {
      setCurrentPage(location.pathname);
    }, [location]);

    const handleLanguageChange = () => {
      setLanguage(lang === "en" ? "cs" : "en");
    };

    const renderMobileMenuItems = () =>
    menuItems.map((item) => (
      <CustomLink
        key={item.to}
        to={item.to}
        color={dark ? '#fff' : '#000'}
        strikeThrough={currentPage === item.to}
      >
        {item.label}
      </CustomLink>
    ));

    const menuItems = [
      {
        to: '/',
        label: lang === 'en' ? 'HOUSE' : 'DŮM',
      },
      {
        to: '/history',
        label: lang === 'en' ? 'HISTORY' : 'HISTORIE',
      },
      {
        to: '/spaces',
        label: lang === 'en' ? 'SPACES' : 'PROSTORY',
      },
    ];

    const CustomLink = ({ to, children, strikeThrough, ...rest }) => (
      <Link to={to} {...rest}>
        <Anchor
          as="span"
          {...rest}
          style={{
            opacity: currentPage === to ? 0.5 : 1, // Add opacity based on currentPage
            textDecoration: 'none',
            fontWeight: 700,
            fontSize: "18px",
            paddingTop: "2px",
            ...rest.style,
          }}
        >
          {children}
        </Anchor>
      </Link>
    );
  
    return (
      <AppBar
        background={dark ? '#000' : 'brand'}
        style={{
          position: 'fixed',
          borderRadius: '50px',
          top: '0',
          width: '95%',
          alignSelf: 'center',
          zIndex: '1000',
          height: '70px', // Add height to AppBar
          overflow: 'hidden'
        }}
      >
      <Box direction="row" align="center" gap="small">
        <Link to="/">
          <Box height="xxsmall" width="xxsmall">
            <SvgLogo className={`st1-logo ${dark ? 'dark-mode' : ''}`} />
          </Box>
        </Link>
      </Box>
      <ResponsiveContext.Consumer>
        {(responsive) =>
          responsive === 'small' ? (
            <DropButton
              label="MENU"
              dropAlign={{
                top: 'bottom',
                right: 'right',
                left: 'left',
              }}
              dropContent={
                <Box
                  pad="medium"
                  background={dark ? '#000' : 'brand'}
                  style={{
                    border: '0.5px solid black',
                    borderRadius: '20px',
                    marginTop: '8px',
                    fontFamily: "Jan Bold"
                  }}
                >
                  <Nav direction="column" className="mobile-nav">
                    {renderMobileMenuItems()}
                  </Nav>
                </Box>
              }
              style={{
                width: '40vw', // Adjust the width
                background: 'none',
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none', // Remove the drop shadow
              }}
            />
          ) : (
            <Nav direction="row" gap="small">
              <Text style={{fontWeight: 700, fontSize:"18px", fontFamily: "Jan Bold" }}>·</Text>
              <CustomLink
                to="/"
                style={{ fontWeight: 700, fontSize: "18px", paddingTop: "2px", fontFamily: "Jan Bold" }}
                color={dark ? "#fff" : "#000"}
                strikeThrough={currentPage === '/'}
              >
                {lang === 'en' ? "HOUSE" : "DŮM"}
              </CustomLink>
              <Text style={{fontWeight: 700, fontSize:"18px", fontFamily: "Jan Bold" }}>·</Text>
              <CustomLink
                to="/history"
                style={{ fontWeight: 700, fontSize: "18px", paddingTop: "2px", fontFamily: "Jan Bold" }}
                color={dark ? "#fff" : "#000"}
                strikeThrough={currentPage === '/history'}
              >
                {lang === 'en' ? "HISTORY" : "HISTORIE"}
              </CustomLink>
              <Text style={{fontWeight: 700, fontSize:"18px", fontFamily: "Jan Bold" }}>·</Text>
              <CustomLink
                to="/spaces"
                style={{ fontWeight: 700, fontSize: "18px", paddingTop: "2px", fontFamily: "Jan Bold" }}
                color={dark ? "#fff" : "#000"}
                strikeThrough={currentPage === '/spaces'}
              >
                {lang === 'en' ? "SPACES" : "PROSTORY"}
              </CustomLink>
              <Text style={{fontWeight: 700, fontSize:"18px", fontFamily: "Jan Bold" }}>·</Text>
            </Nav>
          )
        }
      </ResponsiveContext.Consumer>
      <Box direction="row" align="center" gap="xsmall">
        <Button 
          label={lang === "en" ? "CZ" : "EN"} 
          // a11yTitle={(lang === "en") ? "Switch to Czech" : "Switch to English"}
          onClick={handleLanguageChange} 
        />
        <Text style={{fontWeight: 700, fontSize:"18px", fontFamily: "Jan Bold" }}>·</Text>
        <Button
          //a11yTitle={dark ? "Switch to Light Mode" : "Switch to Dark Mode"}
          icon={dark ? <Moon /> : <Sun />}
          onClick={() => setDark(!dark)}
          /*tip={{
            content: (
              <Box
                pad="small"
                round="small"
                background={dark ? "dark-1" : "light-3"}
              >
                { dark ? "Switch to Light Mode" : "Switch to Dark Mode" }
              </Box>
            ),
            plain: true,
          }} */
        />
      </Box>
    </AppBar>
  );
};

export default MenuBar;