import React, { useState, useEffect } from "react";
import { customTheme } from './settings/Theme.js';
import HomepageCover from "./components/HomepageCover.js";
import HomepageContent from "./components/HomepageContent.js";
import MenuBar from './components/MenuBar.js';
import {
  Grommet,
  Page,
} from "grommet";
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import History from "./components/History.js"; // Import History component
import Spaces from "./components/Spaces.js"; // Import Spaces component
import Chat from "./components/Chat.js"; // Import Chat component
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import loadingGif from "./img/logo_anim.gif";
const AppContent = ({ dark, setDark }) => {
  const [lang, setLanguage] = useState("cs");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const langParam = urlParams.get("lang");

    if (langParam && langParam !== lang && isValidLanguage(langParam)) {
      setLanguage(langParam);
    } else if (!langParam) {
      handleLanguageChange(lang);
    }
  }, [lang, location, navigate]);

  const handleLanguageChange = (newLang) => {
    if (isValidLanguage(newLang)) {
      setLanguage(newLang);
      const urlParams = new URLSearchParams(location.search);
      urlParams.set("lang", newLang);
      navigate({ search: urlParams.toString() }, { replace: true });
    }
  };

  const isValidLanguage = (lang) => {
    return ["cs", "en"].includes(lang);
  };

  return (
    <Page>
      <MenuBar
        dark={dark}
        setDark={setDark}
        lang={lang}
        setLanguage={handleLanguageChange}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomepageCover lang={lang} dark={dark} />
              <HomepageContent lang={lang} dark={dark} />
            </>
          }
        />
        <Route path="/history" element={<History dark={dark} lang={lang} />} />
        <Route path="/spaces" element={<Spaces dark={dark} lang={lang} />} />
        <Route path="/chat" element={<Chat dark={dark} lang={lang} />} />
        <Route path="/404" element={<Navigate to="/" />} />
        <Route path="/:path*" element={<Navigate to="/404" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Page>
  );
};

const App = () => {
  const [dark, setDark] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // Adjust the duration (in milliseconds) as needed
  
    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <Grommet
      style={{ overflowX: "hidden" }}
      theme={customTheme}
      full
      themeMode={dark ? "dark" : "light"}
    >
      <BrowserRouter>
        {isLoading ? (
          // Loading page
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: '#f3eede' }}>
            <img src={loadingGif} alt="Loading" style={{height: "15vh"}} />
          </div>
        ) : (
          // App content
          <AppContent dark={dark} setDark={setDark} />
        )}
      </BrowserRouter>
    </Grommet>
  );  
};

export default App;