import React from "react";
import { Box, Button, Grid, PageContent, Anchor, Card } from "grommet";
import CardTemplate from "./CardTemplate";
import MailchimpSignupForm from "./EmailSignup.js";
import Testimonials from "./Testimonials";
import { Link } from 'react-router-dom';
import Chatbot from "./Chatbot";

const HomepageContent = ({dark, lang}) => {
  return (
    <PageContent>
      <Box margin={{ 
        bottom: "100px" 
      }}>
      <Grid columns="medium" gap="large" pad={{ bottom: "large" }}>
        <CardTemplate
          title=
            {lang === 'en' ? 'THE LAICHTER HOUSE WAS DESIGNED BY THE ARCHITECT JAN KOTĚRA AND BUILT IN THE YEARS 1908 – 1910 FOR THE PUBLISHING HOUSE OF JAN LAICHTER' : 'LAICHTERŮV DŮM BYL NAVRŽEN ARCHITEKTEM JANEM KOTĚROU A POSTAVEN V LETECH 1908 – 1910 JAKO SÍDLO NAKLADATELSTVÍ JANA LAICHTERA'}
          element={
            <Box margin={{ top: "medium" }} style={{ alignItems: "center" }}>
              <Link to={lang === 'en' ? "https://explore.laichter.house/?lang=en" : "https://explore.laichter.house/"}>
                <Button 
                  as="span" 
                  type="button" 
                  style={{ fontFamily: "Jan Bold" }}
                  label={lang === 'en' ? "· VIRTUAL TOUR ·" : "· VIRTUÁLNÍ PROHLÍDKA ·"}
                  primary 
                />
              </Link>
            </Box>
          }
        />
        <CardTemplate
          title={lang === 'en' ? "· IN THE PRESS ·" : "· NAPSALI O NÁS ·"}
          mainElement={<Testimonials lang={lang} />}
        />
        <CardTemplate
          title={lang === 'en' ? "· VISITS ·" : "· NÁVŠTĚVY ·"}
          text={lang === 'en' ? 
            "Currently, tour options are limited. Sign up for our newsletter and we will send you an invitation when a viewing date opens up. By submitting you agree with personal details processing." : 
            "V současné době jsou možnosti prohlídek omezené. Přihlašte se k odběru novinek a my Vám pošleme pozvánku, až na Vás přijde řada. Potvrzením souhlasíte se zpracováním osobních údajů."
          }
          element={<MailchimpSignupForm dark={dark} lang={lang} />}
        />
        <CardTemplate
          title={lang === 'en' ? "· ADDRESS ·" : "· ADRESA ·"}
          text={
            <Box>
              <Anchor href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8" target="_blank">Chopinova 1543/4</Anchor>
              <Anchor href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8" target="_blank">Praha 2–Vinohrady, 120 00</Anchor>
              <Anchor href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8" target="_blank">Česká republika, EU </Anchor>
            </Box>
          }
          element={
            <Box margin={{ top: "medium" }} style={{ alignItems: "center" }}>
              <Button
                type="button"
                target="_blank"
                style={{ fontFamily: "Jan Bold" }}
                href="https://goo.gl/maps/TwkaY9n9rk6Rdn7b8"
                label={lang === 'en' ? "· MAP ·" : "· MAPA ·"}
                color={"transparent"}
                primary
              />
            </Box>
          }
        />
        <CardTemplate
          title={lang === 'en' ? "· CONTACT ·" : "· KONTAKT ·"}
          text={
            <Box>
              <Anchor href="mailto: dobry.den@laichter.house" label={<span><i>email:</i> dobry.den@laichter.house</span>} />
              <Anchor href="tel:+420776318136" label={<span><i>tel.:</i> +420 776 318 136</span>} />
            </Box>
          }
          element={
            <Box margin={{ top: "medium" }} style={{ alignItems: "center" }}>
              <Button
                type="button"
                style={{ fontFamily: "Jan Bold" }}
                target="_blank"
                href="https://www.instagram.com/laichter.house/"
                label="· INSTAGRAM ·"
                color={"transparent"}
                primary
              />
            </Box>
              }
            />
          <Card 
            elevation="none"
            border={true}
            round="xsmall"
            pad="xsmall"
            >
            <Chatbot dark={dark} lang={lang}/>
          </Card>
        </Grid>
        </Box>
    </PageContent>
  );
};

export default HomepageContent;